import React from 'react'

export default props => (
	<div id={props.id} 
		class={`${props.responsive ? 'table-responsive' : ''} ${props.border ? 'table-bordered' : ''}`} 
		style={props.alturaFixa ? {
			display: "flex",
			overflowX: "auto",
			maxHeight: props.maxHeight || "75vh",
			height: props.height,
			minWidth: props.minWidth,
			overflowY: "scroll",
			scrollBehavior: 'smooth'
		} : { 
			display: 'flex',
			overflowX: 'auto' 
		}}>
	    <table className={`table table-hover ${props.tableBordered ? 'table-bordered' : ''}`}>
			{props.children}
		</table>
	</div>
)
